import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

window.gsap = gsap;

gsap.registerPlugin(ScrollTrigger);

import 'slick-carousel';

/* Sliders */

$('.video-slider__slider').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	autoplaySpeed: 18000,
	pauseOnFocus: true,
	pauseOnHover: true,
	pauseOnDotsHover: false,
	fade: false,
	infinite: true,
	draggable: true,
	arrows: false,
	dots: true,
	adaptiveHeight: true,
});

$('.slider').slick({
	arrows: false,
	dots: true,
	autoplay: true,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	adaptiveHeight: true,
	mobileFirst: true,
	responsive: [
		{
			breakpoint: 1023,
			settings: {
				adaptiveHeight: false,
			},
		},
	],
});

$('.product-colours__gallery').slick({
	arrows: false,
	dots: true,
	autoplay: true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	slidesToShow: 7,
	slidesToScroll: 1,
	appendDots: '.product-colours-dots',
	responsive: [
		{
			breakpoint: 9999,
			settings: 'unslick',
		},
		{
			breakpoint: 620,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
	],
});

$('#product-application__slider').slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 760,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});

$('#product-market__slider').slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 760,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});


$('#markets-slider__slider').slick({
	arrows: true,
	dots: false,
	infinite: true,
	slidesToShow: 6,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 4000,
	pauseOnHover: true,
	lazyLoad: 'progressive',
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				dots: false,
			},
		},
		{
			breakpoint: 760,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
			},
		},
	],
});

$('#featured-slider').slick({
	arrows: false,
	dots: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
});

$('#product-filter input').on('change', function () {
	$('#product-filter .hiddenButton').click();
});

$('#product-filter').on('submit', function () {
	$('.product-filter').removeClass('product-filter--open');

	var filter = $('#product-filter');

	$.ajax({
		url: filter.attr('action'),
		data: filter.serialize(),
		type: filter.attr('method'),

		success: function (data) {
			// console.log(data);
			$('#ajax-results').html(data);
		},
	});
	return false;
});

$('#product-filter input').on('change', function () {
	if ($(this).parent().siblings().find('input:checkbox').length >= 1) {
		$(this)
			.parent()
			.siblings()
			.find('input:checkbox')
			.prop('checked', false);
	}
});

$('.product-filter__dropdown').on('click', function () {
	if ($(window).width() >= 1024) {
		var dropdownItemsHeight = 32;
	} else {
		var dropdownItemsHeight = 25;
	}

	var dropdownItems = $(this)
		.children('.product-filter-dropdown__content')
		.children('.input-wrapper').length;
	var inputHeight = $(this)
		.children('.product-filter-dropdown__content ')
		.outerHeight(true);
	var icon = $(this)
		.children('.flex')
		.children('.product-filter-dropdown__icon');

	icon.toggleClass('active');

	if ($(this).children('.product-filter-dropdown__content').height() > 40) {
		$(this).children('.product-filter-dropdown__content').outerHeight(0);
		$(this)
			.children('.product-filter-dropdown__content')
			.toggleClass('product-filter-dropdown__content--active');
	} else {
		$(this)
			.siblings()
			.children('.product-filter-dropdown__content')
			.outerHeight(0);
		$(this)
			.siblings()
			.children('.product-filter-dropdown__content')
			.removeClass('product-filter-dropdown__content--active');
		$(this)
			.children('.product-filter-dropdown__content')
			.addClass('product-filter-dropdown__content--active');
		$(this)
			.children('.product-filter-dropdown__content')
			.height(dropdownItems * dropdownItemsHeight);
	}
});

$('.product-filter__dropdown--mobile').on('click', function () {
	$(this).outerHeight(40);
	$('.product-filter').toggleClass('product-filter--open');
});

$('.product-filter__dropdown input').on('change', function () {
	var textLabel = $(this).siblings().text();
	var textLabelPlaceholder = $(this)
		.closest('.product-filter__dropdown')
		.children('.flex')
		.find('p')
		.data('placeholder');

	$(this)
		.closest('.product-filter__dropdown')
		.children('.product-filter-dropdown__content')
		.outerHeight(0)
		.removeClass('product-filter-dropdown__content--active');
	// var tmp = $(this).parent().siblings().find('input:checkbox').length;
	// if( $(this).parent().siblings().find('input:checkbox').length >= 1 ) {
	//     // alert(tmp);
	//     $(this).closest('.product-filter__dropdown').children('.flex').find('p').text(textLabel);
	// }

	// if( $(this).parent().siblings().find('input:checkbox').is(":not(:checked)") ) {
	//     // alert();
	//     var textLabelPlaceholder = $(this).closest('.product-filter__dropdown').children('.flex').find('p').data('placeholder');
	//     alert(textLabelPlaceholder);
	//     $(this).closest('.product-filter__dropdown').children('.flex').find('p').text(textLabelPlaceholder);
	// }

	if ($(this).is(':checked')) {
		$(this)
			.closest('.product-filter__dropdown')
			.children('.flex')
			.find('p')
			.text(textLabel);
	} else if ($(this).is(':not(:checked)')) {
		$(this)
			.closest('.product-filter__dropdown')
			.children('.flex')
			.find('p')
			.text(textLabelPlaceholder);
	}
});

/* BRANDS BUTTON OPEN MENU  */
$('.brands__btn').on('click', function (e) {
	e.preventDefault();
	$('#brands-nav').slideToggle();
	$('#brands-nav').css('top', $('.header').height());
});

/* BANNER DOWN BUTTONS */

$('#banner-btn__down').on('click', function () {
	$([document.documentElement, document.body]).animate(
		{
			scrollTop: $('main').offset().top - 78,
		},
		2000
	);
});

$('#banner-btn__down--tax').on('click', function () {
	$([document.documentElement, document.body]).animate(
		{
			scrollTop: $('.markets-intro').offset().top - 78,
		},
		2000
	);
});

$('.form--findadist').submit(function (e) {
	e.preventDefault();
	var ajaxurl = $('.form--findadist');
	var $daForm = $(this);
	var data = $(this).serializeArray();

	$.ajax({
		url: ajaxurl.attr('action'),
		data: data,
		success: function (data) {
			var response = JSON.parse(data);

			if (response.status == 'success') {
				$daForm.hide();
				$('.success-message').fadeIn();
			} else if (response.status == 'fail') {
				$('.error-message').fadeIn();
				$('.error-message').html('');
				$(response.errors).each(function (i, index) {
					$('.error-message').append(
						'<p style="margin-top: 20px;" class="signup-container__text grey">' +
						index +
						'</p>'
					);
				});
			}
		},
		error: function (errorThrown) {
			console.log(errorThrown);
		},
	});
});

$('.form--contact').submit(function (e) {
	e.preventDefault();
	var ajaxurl = $('.form--contact');
	var $daForm = $(this);
	var data = $(this).serializeArray();

	$.ajax({
		url: ajaxurl.attr('action'),
		data: data,
		success: function (data) {
			var response = JSON.parse(data);

			if (response.status == 'success') {
				$daForm.hide();
				$('.success-message').fadeIn();
				contact_gtag_report_conversion();
			} else if (response.status == 'fail') {
				$('.error-message').fadeIn();
				$('.error-message').html('');
				$(response.errors).each(function (i, index) {
					$('.error-message').append(
						'<p style="margin-top: 20px;" class="signup-container__text grey">' +
						index +
						'</p>'
					);
				});
			}
		},
		error: function (errorThrown) {
			console.log(errorThrown);
		},
	});
});

$('.form--product-enquiry').submit(function (e) {
	e.preventDefault();
	var ajaxurl = $('.form--product-enquiry');
	var $daForm = $(this);
	var data = $(this).serializeArray();

	$.ajax({
		url: ajaxurl.attr('action'),
		data: data,
		success: function (data) {
			var response = JSON.parse(data);

			if (response.status == 'success') {
				$daForm.hide();
				$('.success-message').fadeIn();
				product_gtag_report_conversion();
			} else if (response.status == 'fail') {
				$('.error-message').fadeIn();
				$('.error-message').html('');
				$(response.errors).each(function (i, index) {
					$('.error-message').append(
						'<p style="margin-top: 20px;" class="signup-container__text grey">' +
						index +
						'</p>'
					);
				});
			}
		},
		error: function (errorThrown) {
			console.log(errorThrown);
		},
	});
});

$('.enquiry-popup .form--product-enquiry').submit(function (e) {
	e.preventDefault();
	var ajaxurl = $('.enquiry-popup .form--product-enquiry');
	var $daForm = $(this);
	var data = $(this).serializeArray();

	$.ajax({
		url: ajaxurl.attr('action'),
		data: data,
		success: function (data) {
			var response = JSON.parse(data);

			if (response.status == 'success') {
				$daForm.hide();
				$('.success-message').fadeIn();
			} else if (response.status == 'fail') {
				$('.error-message').fadeIn();
				$('.error-message').html('');
				$(response.errors).each(function (i, index) {
					$('.error-message').append(
						'<p style="margin-top: 20px;" class="signup-container__text grey">' +
						index +
						'</p>'
					);
				});
			}
		},
		error: function (errorThrown) {
			console.log(errorThrown);
		},
	});
});

// $('.news-filter .news-filter__item').on('click', function() {

//     var dataAttrClicked = $(this).data('news-filter');

//     var filterArgs = {
//         action: 'newsfilter',
//         data:  dataAttrClicked
//     };

//     $.ajax({
//         url: 'http://localhost:3000/wp-admin/admin-ajax.php',
//         data: filterArgs,

//         success:function(data) {
//             console.log(data);
//             // $('.news-directory').html(data);
//         },
//         error: function (errorThrown) {
//             console.log(errorThrown);
//         }
//     });
//     return false;

// });

$('.mobile-icons__icon--menu').on('click', function () {
	$('.header__container').toggleClass('header__container--open');
	$('.mobile-icons__icon--menu').toggleClass('mobile-icons__icon--open');
});

if ($(window).width() >= 1024) {
	$('.menu-item-has-children > a').on('click', function (e) {
		e.preventDefault();

		var navItem = $(this);
		var menuID = $(this).attr('href');
		$(navItem).css('top', $('.header').height());

		if ($('.product-finder').hasClass('product-finder--open')) {
			$('.product-finder').removeClass('product-finder--open');
			$('.product-finder-btn')
				.children('.close')
				.removeClass('show-close');
			$('.product-finder-btn').children('.icon').removeClass('show-icon');
		}

		if (navItem.hasClass('menu-open')) {
			navItem.removeClass('menu-open');
			$(menuID).slideUp();
			$('body').removeClass('no-scroll');
		} else {
			$('.menu-content').slideUp();
			$('.menu-item-has-children > a').removeClass('menu-open');
			navItem.addClass('menu-open');
			$(menuID).slideDown();
			$('body').addClass('no-scroll');
		}

		if ($('.search-dropdown').css('display') == 'block') {
			$('.search-dropdown').slideUp();
			$('body').removeClass('no-scroll');
			$('.header__search-icon .close').removeClass('flex');
			$('.header__search-icon .close').siblings('.search-icon').fadeIn();
		}
	});

	$('.main-dropdown-nav__close').on('click', function (e) {
		e.preventDefault();

		$('body').removeClass('no-scroll');
		$(this).parent().css('top', $('.header').height());
		$(this).parent().slideToggle();
	});
} else {
	$('.move-mobile').each(function () {
		$(this).insertBefore($(this).parent().parent());
	});

	$('.menu-item-has-children > a').on('click', function (e) {
		e.preventDefault();

		$('.sub-menu').slideUp();
		$(this).siblings().slideToggle();
	});

	$('.main-dropdown-nav__close').on('click', function (e) {
		e.preventDefault();

		$(this).parent().css('top', $('.header').height());
		$(this).parent().slideToggle();
	});
}

$('.breadcrumbs__contents__close').on('click', function () {
	$('.breadcrumbs').hide();
	$('.header').show();
	$('body').css('padding-top', '130px');
	$(this).hide();
	$('.product-finder').addClass('push-down');
});

$('.product-finder-wrapper').on('click', function () {
	if ($(window).width() <= 1024) {
		$('.product-finder').css('top', '70px');
	} else if ($(window).scrollTop() <= 130) {
		$('.product-finder').css('top', '130px');
	} else {
		$('.product-finder').css('top', '80px');
	}

	if ($('body').hasClass('tax-company') || $('body').hasClass('tax-range')) {
		$('.product-finder').css('top', '70px');
	}

	$('.product-finder').toggleClass('product-finder--open');
	$('body').toggleClass('no-scroll');
	$('.product-finder-btn').children('.close').toggleClass('show-close');
	$('.product-finder-btn').children('.icon').toggleClass('show-icon');

	if ($('.menu-item-has-children > a').hasClass('menu-open')) {
		$('.menu-content').slideUp();
		$('body').addClass('no-scroll');
		$('.menu-item-has-children > a').removeClass('menu-open');
	}
});

$('.header__search-icon .search-icon').on('click', function (e) {
	e.preventDefault();

	if ($('.main-dropdown-nav').css('display') == 'block') {
		$('.main-dropdown-nav').slideUp();
	}

	if ($('.products-dropdown-nav').css('display') == 'block') {
		$('.products-dropdown-nav').slideUp();
	}

	$(this).hide();
	$(this).siblings('.close').addClass('flex');
	$('.search-dropdown').slideDown();
	$('.search-dropdown').css('top', $('.header').height());
	$('body').addClass('no-scroll');
});

$('.header__search-icon .close').on('click', function (e) {
	e.preventDefault();

	$(this).removeClass('flex');
	$(this).siblings('.search-icon').fadeIn();
	$('.search-dropdown').slideUp();
	$('body').removeClass('no-scroll');
});

$('.mobile-icons__icon--search').on('click', function (e) {
	e.preventDefault();
	$('.search-dropdown').css('top', $('.header').height());
	$('.search-dropdown').slideToggle();
});

if ($('.secondary-nav').length > 0) {
	//scroll to
	$('.secondary-nav a[href^="#"]').on('click', function (e) {
		e.preventDefault();

		$(this)
			.parent()
			.addClass('secondary-nav__item--active')
			.siblings()
			.removeClass('secondary-nav__item--active');
		console.log($('.header').outerHeight(true));
		var position =
			$($(this).attr('href')).offset().top -
			$('.header').outerHeight(true) -
			$('.secondary-nav').outerHeight(true);

		$('html, body').animate(
			{
				scrollTop: position,
			},
			450
		);
		return false;
	});
}

$('.faqs__item').on('click', function () {
	$(this)
		.toggleClass('faqs__item--active')
		.siblings()
		.removeClass('faqs__item--active');
});

$('.secondary-nav__item--enquiry').on('click', function (e) {
	e.preventDefault();

	var productTitle = $('.product-header__title').text();
	$('.product-enquiry-form').addClass('product-enquiry-form--active');
	$('#enquireyProduct').val(productTitle);
	$('body').addClass('no-scroll');
});

$('.product-enquiry-form__close').on('click', function (e) {
	e.preventDefault();
	$('.product-enquiry-form').removeClass('product-enquiry-form--active');
	$('body').removeClass('no-scroll');
});

$('.team-contact').on('click', function (e) {
	e.preventDefault();

	var hiddenEmail = $(this).attr('data-team-member');
	$('.hiddenEmail').val(hiddenEmail);

	$('.enquiry-popup').addClass('enquiry-popup--active');
	$('body').addClass('no-scroll');
});

$('.enquiry-popup__close').on('click', function (e) {
	e.preventDefault();
	$('.enquiry-popup').removeClass('enquiry-popup--active');
	$('body').removeClass('no-scroll');
});

$(window).on('load', function () {
	$('.header__scroll-up').on('click', function () {
		$('html, body').animate(
			{
				scrollTop: $('html').offset().top,
			},
			1000
		);
	});

	let heroSection = document.getElementById('hero-section');

	if (heroSection) {
		// JavaScript to be fired on the home page, after the init JS
		let isMobile = window.matchMedia('only screen and (max-width: 1024px)').matches;

		$(window).scroll(function () {
			if (isMobile) {
				return false;
			}

			var scroll_pos = $(window).scrollTop();

			if (scroll_pos > 80) {
				$('.hero-border').addClass('active');
				$('.scroll-down-text').css('color', '#eb6608');
				$('.scroll-down-text').css('border-bottom-color', '#eb6608');
				$('.scroll-down-text').css('bottom', '20px');
			} else {
				$('.hero-border').removeClass('active');
				$('.scroll-down-text').css('color', '#fff');
				$('.scroll-down-text').css('border-bottom-color', '#fff');
				$('.scroll-down-text').css('bottom', '40px');
			}
		});

		/* Modal */
		var modal = document.getElementById('myModal');
		var btn = document.getElementById('videoBtn');
		var close = document.getElementById('videoClose');
		var modalVideo = document.getElementById('modalVideo');
		var videoSrc = $(btn).data('src');

		// When the user clicks on the button, open the modal
		btn.onclick = function () {
			modal.style.display = 'block';
			$(modalVideo).attr('src', videoSrc + '&autoplay=1&muted=1&modestbranding=1&showinfo=0');
		}

		// When the user clicks on x, close the modal
		close.onclick = function () {
			modal.style.display = 'none';
			$(modalVideo).attr('src', videoSrc);
		}

		// When the user clicks anywhere outside of the modal, close it
		window.onclick = function (event) {
			if (event.target == modal) {
				modal.style.display = 'none';
				$(modalVideo).attr('src', videoSrc);
			}
		}
	}

	/*
	 * ANIMATIONS
	 */

	setTimeout(function () {
		ScrollTrigger.refresh();
	}, 100);

	if ($('.fadeIn').length > 0) {
		$('.fadeIn').each(function () {
			gsap.fromTo(
				this,
				{
					autoAlpha: 0,
					y: 20,
				},
				{
					scrollTrigger: {
						trigger: this,
						toggleActions: 'play complete none reset',
						start: 'top 90%',
						end: 'top 20%',
					},
					duration: 1.5,
					autoAlpha: 1,
					y: 0,
				}
			);
		});
	}

	if ($('.scaleImg').length > 0) {
		$('.scaleImg').each(function () {
			gsap.fromTo(
				this,
				{
					scale: 1,
				},
				{
					scrollTrigger: {
						trigger: this,
						toggleActions: 'play none reverse reset',
						scrub: 1,
					},
					duration: 1.5,
					scale: 1.14,
				}
			);
		});
	}

	if ($('.staggerFade').length > 0) {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.staggerFade',
				toggleActions: 'play none none reset',
				duration: 0.2,
			},
		});
		$('.staggerFade').each(function () {
			tl.fromTo(
				this,
				{
					autoAlpha: 0,
					y: 20,
				},
				{
					autoAlpha: 1,
					y: 0,
				}
			);
		});
	}

	if ($('.staggerFadeLow').length > 0) {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.staggerFade',
				toggleActions: 'play none none reset',
				duration: 0.2,
			},
		});
		$('.staggerFadeLow').each(function () {
			tl.fromTo(
				this,
				{
					autoAlpha: 0,
					y: 20,
				},
				{
					autoAlpha: 1,
					y: 0,
				}
			);
		});
	}

	// if ($(window).width() >= 1024 && $('.history__left').length > 0) {

	//     var stickyLength = $('.history__right').height() / 1.5;

	//     new ScrollMagic.Scene({
	//         triggerHook: .3,
	//         triggerElement: '.history__left',
	//         duration: stickyLength
	//     })
	//     .setPin(".history__left")
	//
	// }

	// if ($(window).width() >= 1024 && $('.rope-technology .flex__left').length > 0) {

	//     var stickyLengthRope = $('.history__right').height() / 1.75;

	//     new ScrollMagic.Scene({
	//         triggerHook: .3,
	//         triggerElement: '.rope-technology .flex__right',
	//         duration: stickyLengthRope
	//     })
	//     .setPin(".rope-technology .flex__left")
	//
	// }
});

if ($('.secondary-nav').length > 0) {
	var distance = $('.secondary-nav').offset().top - 50;
}

/* Lazyload Social Wall */
if (
	$('body.home').length > 0 ||
	$('body.page-template-template-social-wall').length > 0
) {
	$(window).scroll(lazyLoadJuicer);

	var feed = $('.juicer-feed');

	var juicerFeedScrollDistance = 0;
	if(feed.length) {
		juicerFeedScrollDistance = feed.offset().top;
	}

	var juicerLoaded = false;

	function lazyLoadJuicer() {
		var scrollDistance = $(window).scrollTop();
		var windowHeight = $(window).height();

		if (
			scrollDistance >= juicerFeedScrollDistance - windowHeight &&
			!juicerLoaded
		) {
			$.getScript('//assets.juicer.io/embed.js');
			$('head').append(
				'<link rel="stylesheet" type="text/css" href="//assets.juicer.io/embed.css">'
			);
			juicerLoaded = true;
		}
	}

	lazyLoadJuicer();

}

$(window).scroll(function () {
	if ($(window).width() >= 1024) {
		if ($(this).scrollTop() >= 130) {
			$('body').addClass('window-scrolled');
			$('.header').addClass('header--scrolled');

			if ($('.product-breadcrumbs').length) {
				$('.product-breadcrumbs').addClass(
					'product-breadcrumbs--scrolled'
				);
			}
		} else {
			$('body').removeClass('window-scrolled');
			$('.header').removeClass('header--scrolled');

			if ($('.product-breadcrumbs').length) {
				$('.product-breadcrumbs').removeClass(
					'product-breadcrumbs--scrolled'
				);
			}
		}
	}

	if ($(this).scrollTop() >= 50 && $('body').hasClass('home')) {
		$('.menu-content').css('top', '75px');
	} else {
		$('.menu-content').css('top', '130px');
	}

	if (
		($(this).scrollTop() >= 50 &&
			$('body').hasClass('page-template-template-why-dsm')) ||
		$('body').hasClass('page-template-template-resource-centre') ||
		$('body').hasClass('page-template-template-images') ||
		$('body').hasClass('page-template-template-faqs')
	) {
		$('.menu-content').css('top', '158px');
	}

	if (
		($(this).scrollTop() >= 50 &&
			$('body').hasClass('page-template-template-find-a-distributor')) ||
		$('body').hasClass('page-template-template-sustainability')
	) {
		$('.menu-content').css('top', '75px');
	}

	if ($('body').hasClass('tax-range') && $(this).scrollTop() >= 50) {
		$('.product-finder').removeClass('push-down');
	}

	if ($(this).scrollTop() >= distance && $('body').hasClass('category')) {
		$('.secondary-nav').addClass('secondary-nav--fixed-cat');
	} else if (
		$(this).scrollTop() >= distance &&
		$('body').hasClass('page-template-template-images')
	) {
		$('.secondary-nav').addClass('secondary-nav--fixed-cat');
	} else if (
		$(this).scrollTop() >= distance &&
		$('body').hasClass('page-template-template-faqs')
	) {
		$('.secondary-nav').addClass('secondary-nav--fixed-cat');
	} else if ($(this).scrollTop() >= distance) {
		$('.secondary-nav').addClass('secondary-nav--fixed');
	} else {
		$('.secondary-nav').removeClass('secondary-nav--fixed');
		$('.secondary-nav').removeClass('secondary-nav--fixed-cat');
	}
});

$(window).on('resize', function () {
	// HIDE PRODUCTS NAV < TABLET
	if ($(window).width() < 1194) {
		$('#products-nav').css('display', 'none');
	}
});

// Scrolling Text
var scrolling_text = '.scrolling-text';

if ($(scrolling_text).length) {

	$(scrolling_text).each(function () {
		let scrollingTextLeft = $(this).children('.scrolling-text__item--left');
		let scrollingTextRight = $(this).children('.scrolling-text__item--right');
		let mediaMatch = gsap.matchMedia();

		mediaMatch.add("(min-width: 1100px)", () => {
			let scrollingText = gsap.timeline({
				scrollTrigger: {
					trigger: $(this),
					start: 'top bottom',
					end: 'top top',
					scrub: 1,
					invalidateOnRefresh: true,
					//markers: true,
				},
			});
			scrollingText.to(
				scrollingTextLeft,
				{
					x: '10%',
				},
				0
			);
			scrollingText.to(
				scrollingTextRight,
				{
					x: '-15%',
				},
				0
			);
		});

		mediaMatch.add("(max-width: 1099px)", () => {
			let scrollingText = gsap.timeline({
				scrollTrigger: {
					trigger: $(this),
					start: 'top bottom',
					end: 'bottom top',
					scrub: 1,
					invalidateOnRefresh: true,
					//markers: true,
				},
			});

			scrollingText.to(
				scrollingTextLeft,
				{
					x: '15%',
				},
				0
			);
			
			scrollingText.to(
				scrollingTextRight,
				{
					x: '-75%',
				},
				0
			);
		});

	});
}